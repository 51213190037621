<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            codeGroupCd="CHM_DANGER_TYPE_CD"
            label="구분"
            itemText="codeName"
            itemValue="code"
            name="chmDangerTypeCd"
            type="search"
            v-model="searchParam.chmDangerTypeCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="위험물저장소 점검항목 목록"
          tableId="table"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" :showLoading="false" icon="add" @btnClicked="add" />
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="grid.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>
<script>
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dangerStorageCheckItem',
  props: {
    param: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      data: {
        chmDangerStorageCheckItemId: '',  
        chmDangerTypeCd: '',
        chmDangerTypeName: '',  
        dangerCheckItem: '',  // 항목
        dangerCheckContent: '', // 내용
        dangerCheckMethod: '',  // 방법
        sortOrder: null,  // 순번
        useFlag: 'Y',  // 사용여부
        regUserId: '',
        chgUserId: '',
      },
      searchParam: {
        chmDangerTypeCd: 'CDTC000001',
        useFlag: 'Y'
      },
      editable: true,
      grid: {
        columns: [
          {
            name: 'chmDangerTypeCd',
            field: 'chmDangerTypeCd',
            codeGroupCd: 'CHM_DANGER_TYPE_CD',
            required: true,
            label: '구분',
            align: 'center',
            style: 'width: 110px',
            sortable: true,
            type: 'select',
          },
          {
            name: 'dangerCheckItem',
            field: 'dangerCheckItem',
            required: true,
            label: '점검항목',
            align: 'left',
            style: 'width: 300px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'dangerCheckContent',
            field: 'dangerCheckContent',
            required: true,
            label: '점검내용',
            align: 'left',
            style: 'width: 300px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'dangerCheckMethod',
            field: 'dangerCheckMethod',
            label: '점검방법',
            align: 'left',
            style: 'width: 100px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 50px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: true,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'right',
            style: 'width: 50px',
            sortable: true,
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [],
      listUrl: '',
      isSave: false,
      saveType: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.danger.masterItems.list.url;
      this.saveUrl = transactionConfig.chm.danger.masterItem.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        chmDangerStorageCheckItemId: uid(),
        chmDangerTypeCd: null,
        dangerCheckItem: '',  // 항목
        dangerCheckContent: '', // 내용
        dangerCheckMethod: '',  // 방법
        useFlag: 'Y',
        sortOrder: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      });
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',  
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
